// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgTools = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.891 9.123 6.53 6.762l-1.682.56a.75.75 0 0 1-.88-.325L1.847 3.46a.75.75 0 0 1 .112-.916l1.415-1.414a.75.75 0 0 1 .916-.113L7.825 3.14a.75.75 0 0 1 .326.88l-.56 1.682 1.28 1.28c.172-1.33.708-2.62 1.71-3.622 1.007-1.008 2.406-1.594 3.77-1.792 1.356-.198 2.79-.026 3.863.618a.75.75 0 0 1 .145 1.174L15.51 6.207l-.176 1.945 1.944-.177 2.849-2.848a.75.75 0 0 1 1.173.144c.645 1.074.816 2.507.619 3.863-.198 1.364-.785 2.763-1.792 3.77a5.8 5.8 0 0 1-1.63 1.14l3.104 3.103a2.75 2.75 0 0 1-3.889 3.89l-5.687-5.687-5.687 5.687a2.75 2.75 0 0 1-3.889-3.89l6.737-6.736a8 8 0 0 1-.295-1.288m1.847 1.183a.75.75 0 0 1-.157.831L3.51 18.208a1.25 1.25 0 0 0 1.768 1.768l7.05-7.051a.75.75 0 0 1 .845-.18c1.964.762 4.431.56 5.893-.9.732-.733 1.206-1.809 1.369-2.927a5.3 5.3 0 0 0-.054-1.924l-2.234 2.234a.75.75 0 0 1-.463.216l-3.11.283a.75.75 0 0 1-.816-.815l.283-3.11a.75.75 0 0 1 .216-.463l2.234-2.234a5.3 5.3 0 0 0-1.924-.054c-1.118.162-2.194.637-2.926 1.368-1.46 1.46-1.662 3.924-.903 5.887m2.369 4.004 5.666 5.666a1.25 1.25 0 1 0 1.767-1.768l-3.652-3.653a8 8 0 0 1-3.781-.245m-6.968-9-1.194.4-1.51-2.518.585-.586 2.518 1.51z"
    />
  </svg>
);
export const ToolsIcon = forwardRef(SvgTools);
